// 标签云wiki wiki.pageId=174065547
import { PromotionUtils } from '../utils'
const { percentNum, percentNumWithoutSign } = PromotionUtils
import { template } from '@shein/common-function'

function transformSaleTags ({ promotionInfo, language }) {
  // 1.买折 2.买赠 3.限时折扣 9.买N折N 12.会员专项折扣  13.加购价 14.满减 15.N减N元 20.返券 21.返券
  let str = ''
  let { rules = [], typeId, buyLimit, discountValue, rangeList, ruleCrondType, ruleType } = promotionInfo
  const data = rangeList && rangeList.length && rangeList[0] || null    //取一档数据

  switch (String(typeId)) {
    case '1':
      str = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PC_18176)
      break
    case '2':
      str = template(buyLimit, language.SHEIN_KEY_PC_16413)
      break
    case '3':
      str = template(percentNum(discountValue), language.SHEIN_KEY_PC_15957)
      break
    case '9': {
      const tips = []
      if (rangeList?.length && ruleType === 'sale_discount') {
        rangeList.forEach((rule) => {
          const { buyLimit = {}, discountValue = {} } = rule
          tips.push(template(buyLimit.amount, percentNumWithoutSign(discountValue.amount), language.SHEIN_KEY_PC_17083))
        })
        str = tips.join(',')
      } else {
        str = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PC_16681.replace('buyLimit', 0).replace('discountValue', 1))
      }
      break
    }
    case '12':
      str = template(discountValue, language.SHEIN_KEY_PC_16682.replace('discountValue', 0))
      break
    case '13':
      str = template(data?.buyLimit?.amountWithSymbol || '', data?.redemption?.length && data.redemption[0]?.price?.amountWithSymbol || '', language.SHEIN_KEY_PC_15967)
      break
    case '14':
      if (rules.find(obj => obj.type == 4)) { // 每满xxx元活动
        str = template(data?.buyLimit?.amountWithSymbol, data?.discountValue?.amountWithSymbol, language.SHEIN_KEY_PC_29567)
      } else if (rules.find(obj => obj.type == 2)) {  // 每满xxx件活动
        str = template(data?.buyLimit?.amount, data?.discountValue?.amountWithSymbol, language.SHEIN_KEY_PC_29568)
      } else if (ruleCrondType == 1) { //满元减元
        str = template(data?.discountValue?.amountWithSymbol, data?.buyLimit?.amountWithSymbol, language.SHEIN_KEY_PC_15971)
      } else if (ruleCrondType == 2) { //满件减元
        str = template(data?.discountValue?.amountWithSymbol, data?.buyLimit?.amount, language.SHEIN_KEY_PC_15972)
      }
      break
    case '15':
      // 满N件x元
      ruleCrondType == 1 && (str = template(data?.discountValue?.amountWithSymbol, data?.buyLimit?.amount, language.SHEIN_KEY_PC_15975))
      // 每满N件X元
      ruleCrondType == 2 && (str = template(data?.discountValue?.amountWithSymbol, data?.buyLimit?.amount, language.SHEIN_KEY_PC_15976))
      break
    case '18': //买N减N （元）
      // 第二件X元
      rangeList.length == 1 && (str = template(rangeList[0]?.discount?.amountWithSymbol, language.SHEIN_KEY_PC_16154))
      // 第二件X元，第三件Y元
      rangeList.length == 2 && (str = template(rangeList[0]?.discount?.amountWithSymbol, rangeList[1]?.discount?.amountWithSymbol, language.SHEIN_KEY_PC_16156)
      )
      break
    case '19': //买N减N （折扣）
      // 第二件X折
      rangeList.length == 1 && (str = template(percentNum(rangeList[0]?.discount?.amount), language.SHEIN_KEY_PC_16153))
      // 第二件X折，第三件Y折
      rangeList.length == 2 && (str = template(percentNum(rangeList[0]?.discount?.amount), percentNum(rangeList[1]?.discount?.amount), language.SHEIN_KEY_PC_16155))
      break
    case '20':
    case '21':
      str = template(data?.buyLimit?.amountWithSymbol || '', data?.coupon?.priceSymbol || '', language.SHEIN_KEY_PC_16151.replace('x_money', 0).replace('y_money', 1))
      break
  }
  return str
}

export { transformSaleTags }
