import { ref, computed } from 'vue'
import { useEventListener } from '@/public/src/pages/product_list_v2/hooks/useEventListener'

const STYLE_NORMAL = {
  color: '#666',
  backgroundColor: '#FFF',
  border: '1px solid #E5E5E5',
}
const STYLE_HOVER = {
  color: '#666',
  backgroundColor: '#FFF',
  border: '1px solid #000',
}
const STYLE_NORMAL_VIEW_NEW = {
  color: '#666',
  border: 'none',
  backgroundColor: '#F6F6F6',
}
const STYLE_HOVER_VIEW_NEW = {
  color: '#000',
  border: 'none',
  backgroundColor: '#E5E5E5',
}

// 激活状态下背景色添加透明度
function addOpacity (color) {
  if (!color || color.startsWith('rgba')) return color

  let r, g, b

  if (color.startsWith('rgb')) {
    [r, g, b] = color.match(/\d+/g)
  } else {
    const hex = color.replace('#', '')

    if (hex.length === 3) {
      r = parseInt(hex.charAt(0).repeat(2), 16)
      g = parseInt(hex.charAt(1).repeat(2), 16)
      b = parseInt(hex.charAt(2).repeat(2), 16)
    } else {
      r = parseInt(hex.substring(0, 2), 16)
      g = parseInt(hex.substring(2, 4), 16)
      b = parseInt(hex.substring(4, 6), 16)
    }
  }

  return `rgba(${r}, ${g}, ${b}, 0.04)`
}

export function useHover (refTarget) {
  const isHover = ref(false)
  useEventListener('mouseover', () => isHover.value = true, { target: refTarget })
  useEventListener('mouseout', () => isHover.value = false, { target: refTarget })

  return isHover
}

// 文本标签样式
export function useCccStyles ( refActive, refIsViewNew, refHover, cccConfig ) {
  return computed(() => { 
    const {
      checkedBgColor = '#f6f6f6',
    } = cccConfig?.displayContent || {}
      
    if (refActive.value) return {
      color: checkedBgColor,
      backgroundColor: addOpacity(checkedBgColor),
      border: `1px solid ${checkedBgColor }`,
    }

    if (refIsViewNew.value && refHover.value) return STYLE_HOVER_VIEW_NEW
    if (refIsViewNew.value) return STYLE_NORMAL_VIEW_NEW
    if (refHover.value) return STYLE_HOVER
    return STYLE_NORMAL
  })
}

// 图片样式
export function useCccImgData( cccConfig ) {
  return computed(() => {
    const { src, ratio } = cccConfig?.displayContent?.image || {}
    
    return {
      src,
      styles: {
        height: '12px',
        width: `${ratio * 12}px`,
      }
    }
  })
}



// qs标签固定新样式
export function useQsStyles ( refActive, refHover ) {
  return computed(() => { 
    const color = '#198055'

     
    if (refActive.value) return {
      color,
      backgroundColor: addOpacity(color),
      border: `1px solid ${color }`,
    }

    if (refHover.value) return {
      color,
      backgroundColor: '#E5E5E5',
      border: '1px solid #E5E5E5',
    }

    return {
      color,
      backgroundColor: '#F6F6F6',
      border: '1px solid #F6F6F6',
    }
  })
}
