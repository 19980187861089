<template>
  <div
    class="cloud-tag__active-icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      :transform="`rotate(${GB_cssRight ? 270 : 0})`"
    >
      <path
        d="M0 0H16V16L7.5 7.5L0 0Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3536 6.06066L13 7.70711L13.7071 7L12.0607 5.35355L13.7071 3.70711L13 3L11.3536 4.64645L9.70711 3L9 3.70711L10.6464 5.35355L9 7L9.70711 7.70711L11.3536 6.06066Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CloudTagActiveIcon',
  props: {
    color: {
      type: String,
      default: '#000'
    }
  },
  computed: {
    ...mapState([
      'GB_cssRight',
    ]),
  }
}

</script>

<style scoped lang="less">
  .cloud-tag__active-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
