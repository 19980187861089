import { defineAsyncComponent, createVNode } from 'vue'
/**
 * 组件懒加载
 * @param {Component} componentFactory   监听的组件
 * @param {Component} loading            未加载时的占位组件
 * @param {Object}    loadingData        传入占位组件的数据
 * @param {Object}    root               该监听组件的根元素
 * @param {String}    rootMargin         偏移量
 * @param {Array}     thresholds         阈值
 */
export default function lazyLoadComponent({
  componentFactory,
  loading,
  loadingData = {
    props: {
      width: '100%',
      height: '0',
    },
  },
  root = null,
  rootMargin = '0px 0px 40% 0px',
  thresholds = [0],
}) {
  let resolveComponent = null

  return defineAsyncComponent({
    // 加载函数
    loader: () => new Promise((resolve) => {
      resolveComponent = resolve
    }),
  
    // 加载异步组件时使用的组件
    loadingComponent: {
      mounted() {
        // 不支持 IntersectionObserver 事件则立即加载组件
        if (!('IntersectionObserver' in window)) {
          componentFactory().then(resolveComponent)
          return
        }

        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].intersectionRatio <= 0) return

            // 停止观察
            observer.unobserve(this.$el)
            // 加载组件
            componentFactory().then(resolveComponent)
          },
          {
            root,
            rootMargin,
            thresholds,
          }
        )

        // 开始观察
        observer.observe(this.$el)
      },
      render() {
        return createVNode(loading, loadingData)
      },
    }
  })
}
