<template>
  <div
    v-if="isViewNew || (newCloudTags && newCloudTags.length && showTopInfo)"
    ref="clundTagsContainer"
    class="cloud-tags"
  >
    <ul 
      class="cloud-tags__items"
      :class="isViewNew && 'cloud-tags__view-new'"
      role="radiogroup"
    >
      <slot name="prefix"></slot>
      <template
        v-for="(item, index) in newCloudTags"
        :key="item.tag_id"
      >
        <CloudTagQs 
          v-if="item.isNewQs && isViewNew"
          v-expose="{ id: '1-4-2-9', code: exposeCode }"
          :item="item"
          :active="item.tag_id == tagId"
          :data-tag-id="item.tag_id"
          :data-index="index + 1"
          role="radio"
          :tabindex="0"
          :is-view-new="isViewNew"
          :aria-checked="item.tag_id == tagId"
          @click="($event) => clickCloudTag(item, $event)"
        />
        <CloudTagCccConfig 
          v-else-if="item.cccConfig" 
          v-show="index < hiddenTagIdxFlag || hiddenTagIdxFlag === -1"
          v-expose="{ id: '1-4-2-9', code: exposeCode }"
          :item="item"
          :active="item.tag_id == tagId"
          :data-tag-id="item.tag_id"
          :data-index="index + 1"
          role="radio"
          :tabindex="0"
          :is-view-new="isViewNew"
          :aria-checked="item.tag_id == tagId"
          @click="($event) => clickCloudTag(item, $event)"
        />

        <li
          v-else
          v-show="index < hiddenTagIdxFlag || hiddenTagIdxFlag === -1"
          v-expose="{ id: '1-4-2-9', code: exposeCode }"
          class="cloud-tags__item"
          :class="{
            active: item.tag_id == tagId,
            'cloud-tags__item-mall': item.mall_tag || item.quickship_tag,
            'cloud-tags__item-sale': item.is_sale_tag,
            'cloud-tags__new': item.is_new_tag,     
            'cloud-tags__black-friday': item.is_black_friday_tag, // 年末促销

          }"
          :data-tag-id="item.tag_id"
          :data-index="index + 1"
          role="radio"
          :tabindex="0"
          :aria-checked="item.tag_id == tagId"
          @keydown.enter.space.prevent="clickCloudTag(item, $event)"
          @click="clickCloudTag(item, $event)"
        >
          <Icon
            v-if="item.icon"
            :name="item.icon"
          />
          {{ item.tag_name }}
        </li>
      </template>
      <li
        v-if="!isShowMore && showMore"
        class="cloud-tags__item cloud-tags__item-more"
        @click="hiddenTagIdxFlag = -1,isShowMore = true"
      >
        {{ language.SHEIN_KEY_PC_15685 || 'more' }}
        <sui_icon_more_down_14px_1 size="14px" />
      </li>
      <slot name="suffix"></slot>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { parseQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import mixin from './mixins'
import { expose } from 'public/src/pages/common/analysis/directive'
import CloudTagCccConfig from './cloud_tags/cccConfig/index.vue'
import CloudTagQs from './cloud_tags/CloudTagQs.vue'
import { Icon, sui_icon_more_down_14px_1 } from '@shein-aidc/icon-vue3'
import handleCloudTags from '@/public/src/pages/product_list_v2/utils/cloudTag.js'

const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'CloudTags',
  components: {
    Icon,
    CloudTagQs,
    CloudTagCccConfig,
    sui_icon_more_down_14px_1,
  },
  directives: { expose },
  mixins: [mixin],
  props: {
    adaLevel: {
      type: Number,
      default: 0,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isViewNew: {
      type: Boolean,
      default: false,
    },
    requestLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tagId: '',
      exposeCode: 'cloudTagsExposeCode',
      hiddenTagIdxFlag: -1,
      isShowMore: false
    }
  },
  computed: {
    ...mapGetters([
      'cloudTags',
      'catInfo',
      'fhContext',
      'googleContext',
      'searchKeywords',
      'mallInfo',
      'listAbtResult',
      'cccConfig',
      'isCccxFilterConfig',
    ]),
    ...mapState(['language', 'lang']),
    showPreferredSeller() {
      return this.listAbtResult?.AllListPreferredSeller?.p == 'type=B'
    },
    showTopInfo() {
      return this.listAbtResult?.labelColumn?.p?.labelColumn !== 'hide'
    },
    // 处理标签云 促销标识/abt
    newCloudTags () {
      return handleCloudTags({
        cloudTags: this.cloudTags,
        cccConfig: this.cccConfig,
        catInfo: this.catInfo,
        language: this.language,
        lang: this.lang,
        isViewNew: this.isViewNew,
        mallInfo: this.mallInfo,
        listAbtResult: this.listAbtResult,
        isCccxFilterConfig: this.isCccxFilterConfig
      })
    }
  },
  watch: {
    'catInfo.tag_ids': {
      handler() {
        this.tagId = this.catInfo.tag_ids || ''
      },

      immediate: true,
    },
    newCloudTags() {
      daEventExpose?.resetAll?.(this.exposeCode)
      this.handleShowMore()
    }
  },
  mounted() {
    this.handleShowMore()
  },
  methods: {
    ...mapMutations(['jumpUrl']),
    clickCloudTag({ tag_id, mall_tag }, $event) {
      if (this.requestLoading) return
      let tagId = ''
      let mallTag = ''
      if (this.catInfo.tag_ids !== tag_id) {
        tagId = tag_id
        mallTag = mall_tag
      }
      this.tagId = tagId
      // 修改query上的tag_ids
      this.queryObj = Object.assign(parseQueryString(location.search), {
        tag_ids: this.tagId,
        mall_tag: mallTag,
        source: 'cloudTag',
        sourceStatus: tagId ? 1 : 0,
      })

      let target = $event.target
      while(target && target.tagName !== 'LI' ) {
        target = target.parentElement
      }
      
      // 点击埋点
      daEventCenter.triggerNotice({
        daId: '1-4-2-8',
        target,
        extraData: {
          is_cancel: tagId ? 0 : 1,
          facet: this.fhContext.tag_id,
          gfilter: this.googleContext.gfilter,
        },
      })
      // mixin方法
      this.handleUrl()
      this.$emit('selectCloudTag', { queryObj: this.queryObj })
    },
    handleShowMore() {
      if (!this.showMore) return
      if (typeof window === 'undefined') return
      this.hiddenTagIdxFlag = -1
      this.isShowMore = false
      this.$nextTick(() => {
        const wrapperDom = document.querySelector('.cloud-tags__items')
        const moreDom = document.querySelector('.cloud-tags__item-more')
        if (!wrapperDom || !moreDom) return
        moreDom.style.display = 'inline-block'
        const moreWidth = moreDom?.offsetWidth + 1
        const { left, width = 0 } = wrapperDom?.getBoundingClientRect?.() || {}
        const maxX = left + width
        if (width <= 0) return
        const items = document.querySelectorAll('.cloud-tags__item') || []
        if (!items?.length) return
        let firstRowTop, prevDomInfo
        items.forEach((item, idx) => {
          if (item.classList.contains('cloud-tags__item-more'))
            return
          if (this.hiddenTagIdxFlag > -1)
            return
          const domInfo = item.getBoundingClientRect() || {}
          const { top } = domInfo
          if (firstRowTop === undefined) firstRowTop = top
          if (top > firstRowTop) {
            const { left, width } = prevDomInfo || {}
            this.hiddenTagIdxFlag = idx
            if (left + width + moreWidth + 12 > maxX) {
              this.hiddenTagIdxFlag = idx - 1
            }
          }
          prevDomInfo = domInfo
        })
        if (this.hiddenTagIdxFlag === -1) {
          moreDom.style.display = 'none'
        }
      })
    },
    // 父组件调用，改变选中值
    updateValue(value) {
      this.tagId = value
    }
  },
  emits: ['selectCloudTag']
}
</script>

<style lang="less">

@viewNewHeight: 36px;
/** 
* 带icon的tag样式【qs/促销/年末促销】
* @param {string} @color - icon颜色
*/
.icon_tag(@color) {
  svg {
    color: @color;
  }
  &:hover {
    color: @color;
    border: 1px solid @color;
  }
  &.active {
    border: 1px solid @color;
    color: @color;
    &:before {
      border-color: transparent @color transparent transparent;
    }
  }
}
/** 
* 新样式下带icon的tag样式【qs/促销/年末促销】
* @param {string} @color - icon颜色
*/
.icon_tag_new(@color) {
  &.active:hover {
    color: @color;
    border: 1px solid @color;
  }

  &.active {
    border: 1px solid @color;
    color: @color;
    &:before {
      border-color: transparent @color transparent transparent;
    }
  }
}

.product-list-v2,.sui-popover__content-body {
  .cloud-tags {
    .clearfix();
    &__item {
      .fl();
      white-space: nowrap;
      position: relative;
      height: 27px;
      max-width: 100%;
      line-height: 27px;
      border: 1px solid @sui_color_gray_weak1;
      padding: 0 12px;
      margin: 0 10px 10px 0 /*rtl: ignore*/;
      color: #666;
      cursor: pointer;
      background-color: #fff;
      word-break: break-word;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-right: 2px;
      }
      &:hover {
        border: 1px solid @sui_color_main;
      }
      &.active {
        border: 1px solid @sui_color_main;
        color: @sui_color_main;
        /* rw:begin */
        background-color: #fff7f9;
        &:before {
          position: absolute;
          top: 0;
          .right(0);
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 19px 19px 0;
          border-color: transparent @color_brand transparent transparent;
        }
        &:after {
          position: absolute;
          top: -3px;
          .right(-8px);
          line-height: 1;
          width: 19px;
          height: 19px;
          transform: scale(0.8);
          content: 'x';
          color: #fff;
        }
      }
    }
    &__item-mall {
      .icon_tag(@sui_color_safety);
    }

    &__item-sale {
      .icon_tag(@sui_color_discount_dark);
    }

    &__new {
      svg {
        color: @sui_color_new;
      }
    }
    &__black-friday {
      .icon_tag(@sui_color_promo_dark);

      &.active {
        background: @sui_color_promo_bg;
      }
    }
  }
  .store-page__cloud-tags {
    .cloud-tags__item {
      display: inline-block;
      /* stylelint-disable-next-line declaration-no-important */
      float: none !important;
      background: #F6F6F6;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      color: #222;
      margin: 0;
      border: 0;
      margin: 0 12px 12px 0;
      /* stylelint-disable-next-line selector-max-specificity */
      &.active {
        border: 1px solid @sui_color_main;
      }
    }
    .cloud-tags__item-more {
      display: none;
      margin-right: 0;
    }
  }
}


// 新样式
.cloud-tags .cloud-tags__view-new {
  .cloud-tags__item {
    color: #333;
    height: @viewNewHeight;
    line-height: @viewNewHeight;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 0 16px;
    border: none;
    background: var(--sui-color-gray-weak-2, #F6F6F6);

    /* stylelint-disable-next-line selector-max-specificity */
    &:hover {
      border: none;
      color: #222;
      background: var(--sui-color-gray-weak-1, #E5E5E5);
    }

    /* stylelint-disable-next-line selector-max-specificity */
    &.active {
      border: 1px solid @sui_color_main;
      background: var(--sui-color-gray-weak-2, #F6F6F6);
    }

    /* stylelint-disable-next-line selector-max-specificity */
    &.active:hover {
      border: 1px solid #222;
    }
  }

  .cloud-tags__item-mall {
    .icon_tag_new(@sui_color_safety);
  }

  .cloud-tags__item-sale {
    .icon_tag_new(@sui_color_discount_dark);
  }

  .cloud-tags__black-friday {
    .icon_tag_new(@sui_color_promo_dark);
    
    /* stylelint-disable-next-line selector-max-specificity */
    &.active {
      background: @sui_color_promo_bg;
    }
  }
}
</style>
