<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <component
    :is="currentComponent"
    :item="item"
    v-bind="$attrs"
  />
</template>

<script setup>
import { computed } from 'vue'
import CloudTagCccText from './CloudTagCccText.vue'
import CloudTagCccImage from './CloudTagCccImage.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
// jquery,swiper,vue.ejs.js文件报错，暂时屏蔽异步加载
// const CloudTagCccText = defineAsyncComponent(() => import('./CloudTagCccText.vue'))
// const CloudTagCccImage = defineAsyncComponent(() => import('./CloudTagCccImage.vue'))

const currentComponent = computed(() => {
  return props.item.cccConfig?.displayType == 1 ? CloudTagCccImage : CloudTagCccText
}) 
</script>
