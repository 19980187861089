<!-- 标签云新增QS标签强化实验abt[pageId=1394253484] -->
<template>
  <li
    ref="refTarget"
    :style="styles"
    v-bind="$attrs"
    class="cloud-tag__qs"
    @keydown.enter.space.prevent="$emit('click', $event)"
    @click="$emit('click', $event)"
  >
    <span
      class="cloud-tag__qs-icon"
    >
      <span
        data-v-4d906883=""
        class="sui-icon-common__wrap icon__container__preview__container"
        style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 16px;"
      >
        <svg
          style="width: auto; height: 1em;"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.56888 3.24512H13.4376L12.6589 5.96079L15.8863 6.8827L14.217 12.6512L12.4227 12.6511C12.0901 13.1776 11.4648 13.5574 10.8561 13.5574C10.2481 13.5574 9.8408 13.1785 9.80937 12.6528H5.72754C5.39467 13.1785 4.77007 13.5574 4.16206 13.5574C3.55405 13.5574 3.14679 13.1785 3.11536 12.6528H1.87126L2.73436 9.64285H0.104126L0.471642 8.36117L7.94155 8.36072L9.0441 4.51568H4.20455L4.56888 3.24512ZM14.7185 7.57955L12.3535 6.96716L11.5192 9.61096H14.0966L14.7185 7.57955Z"
            fill="#198055"
          />
          <path
            d="M7.34326 5.79732H2.45041L2.08289 7.079H6.97574L7.34326 5.79732Z"
            fill="#198055"
          />
        </svg>
      </span>
    </span>

    <div>{{ label }}</div>

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </li>
</template>

<script setup>
import { ref, toRef, computed } from 'vue'

// components
// import { Icon, sui_icon_qucikship_flat_16px } from '@shein-aidc/icon-vue3'
import CloudTagActiveIcon from './CloudTagActiveIcon.vue'

// hooks
import { useHover, useQsStyles } from './cccConfig/hooks/index.js'

defineEmits(['click'])
const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  },
})


const refTarget = ref()
const isHover = useHover(refTarget)
const styles = useQsStyles(toRef(props, 'active'), isHover)
const label = computed(() => props.item.quickShipText || props.item.cccConfig?.displayContent?.labelLang || props.item.tag_name)

</script>

<style scoped lang="less">
  .cloud-tag{
    &__qs {
      font-size: 12px;
      margin-right: 8px;

      /* stylelint-disable-next-line declaration-no-important */
      float: left !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      height: 27px;
      margin: 0 10px 0 0 /*rtl: ignore*/;
      color: #666;
      cursor: pointer;
      background-color: #fff;
      word-break: break-word;
      display: flex;
      align-items: center;
      font-style: italic;
      line-height: 16px;
      border: none;
      padding: 8px 16px;
      margin-right: 12px;
      height: 36px;
      background: #f6f6f6;
      margin-bottom: 12px;
    }

    &__qs-icon {
      display: flex;
      color: #198055;
      margin-right: 4px;
    }
  }
</style>
