<template>
  <li
    ref="refTarget"
    :style="styles"
    v-bind="$attrs"
    class="tag-image"
    :class="isViewNew && 'tag-image__view-new'"
    @keydown.enter.space.prevent="$emit('click', $event)"
    @click="$emit('click', $event)"
  >
    <img
      :src="imgData.src"
      :style="imgData.styles"
    />

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </li>
</template>

<script setup>
import { ref, toRef } from 'vue'
import { useHover, useCccImgData, useCccStyles } from './hooks/index.js'

import CloudTagActiveIcon from '../CloudTagActiveIcon.vue'

defineEmits(['click'])
const props = defineProps({
  isViewNew: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => {}
  }
})

const refTarget = ref()
const isHover = useHover(refTarget)
const styles = useCccStyles(toRef(props, 'active'), toRef(props, 'isViewNew'), isHover, props.item.cccConfig)
const imgData = useCccImgData(props.item.cccConfig)
</script>

<style scoped lang="less">
  .tag-image {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    min-width: 60px;
    height: 27px;
    margin-right: 8px;
    position: relative;


    padding: 8px 16px;
    margin-right: 12px;
    line-height: 15px;

    /* stylelint-disable-next-line declaration-no-important */
    float: left !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    max-width: 100%;
    border: 1px solid #e5e5e5;
    margin: 0 10px 10px 0 /*rtl: ignore*/;
    color: #666;
    cursor: pointer;
    background-color: #fff;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      height: 12px;
    }

    &__view-new {
      height: 36px;
      background: #f6f6f6;
      margin-bottom: 12px;
    }
  }
</style>  
