import { transformSaleTags } from 'public/src/pages/common/promotion/logic/transformSaleTags'

// 兼容ccc无样式配置
function addCccConfigMark(tag, config) {
  config.displayContent && (tag.cccConfig = config)

  if (tag?.tag_id === 'quickship' && tag.quickShipText) {
    tag.label = tag.quickShipText
  }
  // 多语言获取
  if (config.tagId == 0) return tag
  tag.label = tag.quickShipText || config.displayContent?.labelLang || config?.tagName || tag.label || tag.tag_name
  return tag
}

// 根据 tagCloudSlotConfig 查找对应的tag的索引
function getTagIndexByTagCloudSlot (cloudTags, slotConfig) {
  const { tagId, tagType } = slotConfig
  if (tagId == 0 && tagType == 11) return cloudTags.findIndex(obj => obj.type == 1) // mall
  if (tagId == 0 && tagType == 12) return cloudTags.findIndex(obj => obj.type == 2) // qs

  return cloudTags.findIndex(obj => tagId != 0 && obj.tag_id == tagId)
}

// 根据 productTypeSortConfig 排序标签云
function sortByProductType ({ cloudTags, cccConfig }) {
  const { productTypeSortConfig = [] } = cccConfig
  if (!productTypeSortConfig.length) return []

  // 标签云外露接ccc配置
  const map = {}
  cloudTags.forEach(item =>  (map[item.tag_id] = item))
  const mallList = cloudTags.filter(obj => obj.type == 1) // mall
  const qsList = cloudTags.filter(obj => obj.type == 2) // qs
  const saleList = cloudTags.filter(obj => obj.tag_type == 100) // 促销
  const showList = [] // 配置展示且已排序的标签
  const hideList = [] // 配置隐藏的标签

  productTypeSortConfig.forEach(item => {
    switch(item.tagType) {
      // mall
      case 11:
        item.isExposed ? showList.push(...mallList.map(obj => addCccConfigMark(obj, item))) : hideList.push(...mallList)
        break
        // qs
      case 12:
        item.isExposed ? showList.push(...qsList.map(obj => addCccConfigMark(obj, item))) : hideList.push(...qsList)
        break
        // 促销
      case 13:
        item.isExposed ? showList.push(...saleList.map(obj => addCccConfigMark(obj, item))) : hideList.push(...saleList)
        break
        //  其他标签
      default:
        map[item.tagId] && (item.isExposed ? showList.push(addCccConfigMark(map[item.tagId], item)) : hideList.push(map[item.tagId]))
        break
    }
  })

  return [
    ...showList,
  ]
}

// 根据 tagCloudSlotConfig 排序标签云
function sortBySlotConfig ({ cloudTags, cccConfig, isCccxFilterConfig }) {
  const { tagCloudSlotConfig = [] } = cccConfig
  if (!tagCloudSlotConfig.length) return cloudTags

  const fixTags = [] // 固定的tag【qs/mall
  const toSortTags = [] // 待插坑的元素[除 qs/mall 外的tag]
  cloudTags.forEach(tag => {
    !isCccxFilterConfig && (tag.type == 1 || tag.type == 2)
      ? fixTags.push(tag)
      : toSortTags.push(tag)
  })
  
  // 已排序的tag
  const sortList = []
  const map = {} // {1: [tag1, tag2], 3: [tag3], 5: [tag4]}
  tagCloudSlotConfig.forEach(config => {
    const index = getTagIndexByTagCloudSlot(toSortTags, config)
    if (index < 0) return 

    const tag = toSortTags.splice(index, 1)[0]
    if (!tag) return

    addCccConfigMark(tag, config)
    !map[config.pitValue] && (map[config.pitValue] = [])
    map[config.pitValue].push(tag)
  })

  Object.keys(map)
    .sort((a, b) => a - b)
    .forEach(i => { 
      const index = Math.max(0, (i - 1))
      if (sortList[index]) {
        sortList.push(...map[i])
        return
      }
      
      let length = sortList.length
      while (length < index) {
        const slotTag = toSortTags.shift()
        if (!slotTag) break
        sortList.push(slotTag)
        length++
      }
      sortList.push(...map[i])
    })
  
  return [...fixTags, ...sortList, ...toSortTags]
}

// 标签云排序[productTypeSortConfig -> tagCloudSlotConfig]
function cloudTagSort ({ cccConfig = {}, cloudTags = [], isCccxFilterConfig }) {
  let newCloudTags = sortByProductType({ cccConfig, cloudTags })
  newCloudTags = sortBySlotConfig({ cccConfig, cloudTags: newCloudTags, isCccxFilterConfig })

  return newCloudTags
}

// 促销标签
function handleSaleTag ({ tag, lang, language, isViewNew }) {
  if (!tag.promotionInfo || !tag.promotionInfo.typeId) return true
  tag.label = transformSaleTags({ lang, language, promotionInfo: tag.promotionInfo })
  tag.icon = isViewNew ? 'sui_icon_activity_16px' : ''
  return true
}

// mall标签
function handleMallTag ({ tag, catInfo, mallInfo }) {
  const { mallCode } = catInfo
  // 列表指定mall的场景（入口跳转到列表带mallCode），过滤掉mall标签筛选，目的不让用户进行任何mall筛选
  if (mallCode) return false

  const { language_mall_name, mall_tags } = mallInfo?.find((mall) => mall.mall_code == tag.tag_id) || {}
  const isOverSeaMall = mall_tags?.includes('4')
  const isLocalMall = mall_tags?.includes('5')
  // 只展示，仅是本地mall的标签
  const isLocalMallOnly = isLocalMall && !isOverSeaMall
  if (isLocalMallOnly && language_mall_name) {
    tag.icon = 'sui_icon_shipping_16px'
    tag.tag_name = language_mall_name
    tag.mall_tag = 1
    tag.local_mall = 1

    return true
  }

  return false
}

// qs标签
function handleQsTag ({ tag, language, listAbtResult }) {
  if (tag.tag_id != 'quickship') return false
  const isNewQs = listAbtResult?.listlabelQuickship?.p?.listlabelQuickship === 'newQuickship'
  const showQuickShip = listAbtResult?.AllListQuickShip?.p === 'type=B'
  const listquickKey = listAbtResult?.listquickshipKey?.p?.listquickKey === 'newKey'
  if (!showQuickShip) return false

  const quickShipText = tag.quickShipText || listquickKey ? language.SHEIN_KEY_PC_30663 : language.SHEIN_KEY_PC_23485
  tag.tag_name = quickShipText
  tag.icon = 'sui_icon_shipping_16px'
  tag.quickship_tag = 1
  tag.isNewQs = isNewQs
  return true
}

// 黑五标签
function handleBlackFridayTag ({ tag }) {
  tag.icon = 'sui_icon_hot_12px'
  return true
}

// new 标签
function handleNewTag ({ tag }) { 
  tag.icon = 'sui_icon_new_16px'
  return true
}

// 标签云多语言处理
function cloudTagFilter ({ cloudTags, catInfo, language, lang, isViewNew, mallInfo, listAbtResult }) {
  const list = cloudTags.filter((item) => {
    if (!item.tag_id) return false
    if (item.is_sale_tag) return handleSaleTag({ tag: item, lang, language })
    if (+item.type === 1) return handleMallTag({ tag: item, catInfo, mallInfo })
    if (+item.type === 2) return handleQsTag({ tag: item, language, listAbtResult })
    if (item.is_black_friday_tag) return handleBlackFridayTag({ tag: item })
    if (isViewNew && item.is_new_tag) return handleNewTag({ tag: item })
    return true
  })

  // 过滤优选卖家标签
  if (listAbtResult?.AllListPreferredSeller?.p == 'type=B') {
    const preferredTag = list.find(item => item.tag_id == '60006881')
    preferredTag && list.splice(list.indexOf(preferredTag), 1)
  }

  return list
}

// 标签云处理
function handleCloudTags ({ cloudTags, cccConfig, catInfo, language, lang, isViewNew, mallInfo, listAbtResult, isCccxFilterConfig }){
  if (cloudTags.length == 0) return cloudTags
  let newCloudTags = cloudTagFilter({ cloudTags, cccConfig, catInfo, language, lang, isViewNew, mallInfo, listAbtResult })

  return cloudTagSort({ cccConfig, cloudTags: newCloudTags, isCccxFilterConfig })
}

export default handleCloudTags
