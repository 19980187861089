import {
  watch,
  isRef,
  unref,
  onUnmounted,
  onDeactivated,
  nextTick, onMounted, onActivated
} from 'vue'

function onMountedOrActivated(hook) {
  let mounted

  onMounted(() => {
    hook()
    nextTick(() => {
      mounted = true
    })
  })

  onActivated(() => {
    if (mounted) {
      hook()
    }
  })
}

export function useEventListener (type, listener, options) {
  if (typeof window === 'undefined') return

  const { target = window, passive = false, capture = false } = options

  let cleaned = false
  let attached

  const add = (target) => {
    if (cleaned) {
      return
    }
    const element = unref(target)

    if (element && !attached) {
      element.addEventListener(type, listener, {
        capture,
        passive,
      })
      attached = true
    }
  }

  const remove = (target) => {
    if (cleaned) {
      return
    }
    const element = unref(target)

    if (element && attached) {
      element.removeEventListener(type, listener, capture)
      attached = false
    }
  }

  onUnmounted(() => remove(target))
  onDeactivated(() => remove(target))
  onMountedOrActivated(() => add(target))

  let stopWatch

  if (isRef(target)) {
    stopWatch = watch(target, (val, oldVal) => {
      remove(oldVal)
      add(val)
    })
  }

  return () => {
    stopWatch?.()
    remove(target)
    cleaned = true
  }
}
