<template>
  <div
    class="skeleton-box"
    :style="{
      width,
      height,
    }"
  ></div>
</template>

<script>
export default {
  name: 'SkeletonBox',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '0px',
    },
  },
}
</script>
